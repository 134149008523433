<template>
  <div>
    <section>
      <b-form-row v-if="restrictAddSecurityMethodToMultipleCity">
        <b-col>
          <b-alert show variant="danger">{{ DISPLAY_MESSAGES.MULTIPLE_CITIES_ADD_SECURITY_METHOD_ERROR }}</b-alert>
        </b-col>
      </b-form-row>
      <template v-else>
        <LoaderIcon v-if="showLoader" />
        <b-form id="security-method-form" name="security-method-form" v-else @submit.prevent="onSubmit" autocomplete="off" novalidate>
          <b-form-row>
            <b-col cols="12" :sm="getGridSize(7, mode)" :md="getGridSize(5, mode)" :lg="getGridSize(3, mode)">
              <b-form-group>
                <h4 class="page-sub-header">Security method name</h4>
                <div class="floating-input-field">
                  <b-form-input
                    id="security-method-name"
                    :class="{
                      'is-invalid': !securityMethodForm.methodName && formSubmitted,
                    }"
                    type="text"
                    placeholder=" "
                    v-model="securityMethodForm.methodName"
                    required
                  ></b-form-input>
                  <label for="security-method-name">Security method name</label>
                  <b-form-invalid-feedback class="d-block" v-if="!securityMethodForm.methodName && formSubmitted"
                    >Security method name required.</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" :sm="getGridSize(6, mode)" :md="getGridSize(6, mode)" :lg="getGridSize(4, mode)" :class="{ 'pt-md-4 mt-md-3': mode === 'page' }">
              <b-form-group>
                <b-form-checkbox
                  id="is-default-security-method"
                  name="is-default-security-method"
                  :disabled="isDefaultMethodExist"
                  v-model="securityMethodForm.isDefault"
                >
                  Set as default security method for all API calls
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12" :sm="getGridSize(7, mode)" :md="getGridSize(5, mode)" :lg="getGridSize(3, mode)">
              <b-form-group>
                <h4 class="page-sub-header">HttpClientType</h4>
                <div class="floating-select-field">
                  <b-form-select
                    id="http-client-type"
                    class="form-control"
                    v-model="securityMethodForm.httpClientType"
                    @input="securityMethodForm.httpClientType = $event"
                    @change="onChangeHttpClientType"
                    :class="{
                      'is-value-exist': securityMethodForm.httpClientType != null,
                      'is-invalid': !securityMethodForm.httpClientType && formSubmitted,
                    }"
                  >
                    <b-form-select-option :key="i" :value="type.value" v-for="(type, i) in httpClientTypeOptions">{{ type.title }}</b-form-select-option>
                  </b-form-select>
                  <label for="http-client-type">HttpClientType</label>
                  <b-form-invalid-feedback class="d-block" v-if="!securityMethodForm.httpClientType && formSubmitted"
                    >HttpClientType required.</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
          <template v-if="securityMethodForm.httpClientType === httpClientTypeEnum.SIMPLE_AUTH">
            <b-form-row>
              <b-col cols="12" :sm="getGridSize(10, mode)" :md="getGridSize(8, mode)" :lg="getGridSize(6, mode)">
                <b-form-group>
                  <h4 class="page-sub-header">API key name</h4>
                  <div class="floating-input-field">
                    <b-form-input
                      id="api-key-name"
                      :class="{
                        'is-invalid': !securityMethodForm.apiKeyName && formSubmitted,
                      }"
                      type="text"
                      placeholder=" "
                      v-model="securityMethodForm.apiKeyName"
                      required
                    ></b-form-input>
                    <label for="api-key-name">API key name</label>
                    <b-form-invalid-feedback class="d-block" v-if="!securityMethodForm.apiKeyName && formSubmitted"
                      >API key name required.</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="12" :sm="getGridSize(10, mode)" :md="getGridSize(8, mode)" :lg="getGridSize(6, mode)">
                <b-form-group>
                  <h4 class="page-sub-header">API key value</h4>
                  <div class="floating-input-field">
                    <b-form-input
                      id="api-key-value"
                      :class="{
                        'is-invalid': !securityMethodForm.apiKeyValue && formSubmitted,
                      }"
                      type="text"
                      placeholder=" "
                      v-model="securityMethodForm.apiKeyValue"
                      required
                    ></b-form-input>
                    <label for="api-key-value">API key value</label>
                    <b-form-invalid-feedback class="d-block" v-if="!securityMethodForm.apiKeyValue && formSubmitted"
                      >API key value required.</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
          </template>
          <template v-if="securityMethodForm.httpClientType === this.httpClientTypeEnum.OAUTH2">
            <b-form-row>
              <b-col cols="12" :sm="getGridSize(10, mode)" :md="getGridSize(8, mode)" :lg="getGridSize(6, mode)">
                <b-form-group>
                  <h4 class="page-sub-header">Authentication URL</h4>
                  <div class="floating-input-field">
                    <b-form-input
                      id="authentication-url"
                      :class="{
                        'is-invalid':
                          (!securityMethodForm.authenticationUrl && formSubmitted) || (!validateUrl(securityMethodForm.authenticationUrl) && formSubmitted),
                      }"
                      type="url"
                      placeholder=" "
                      v-model="securityMethodForm.authenticationUrl"
                      required
                    ></b-form-input>
                    <label for="authentication-url">Authentication URL</label>
                    <b-form-invalid-feedback class="d-block" v-if="!securityMethodForm.authenticationUrl && formSubmitted"
                      >Authentication URL required.</b-form-invalid-feedback
                    >
                    <b-form-invalid-feedback
                      class="d-block"
                      v-if="securityMethodForm.authenticationUrl && !validateUrl(securityMethodForm.authenticationUrl) && formSubmitted"
                      >Invalid url.</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="12" :sm="getGridSize(10, mode)" :md="getGridSize(8, mode)" :lg="getGridSize(6, mode)">
                <b-form-group>
                  <h4 class="page-sub-header">Client ID</h4>
                  <div class="floating-input-field">
                    <b-form-input
                      id="client-id"
                      :class="{
                        'is-invalid': !securityMethodForm.clientId && formSubmitted,
                      }"
                      type="text"
                      placeholder=" "
                      v-model="securityMethodForm.clientId"
                      required
                    ></b-form-input>
                    <label for="client-id">Client ID</label>
                    <b-form-invalid-feedback class="d-block" v-if="!securityMethodForm.clientId && formSubmitted">Client ID required.</b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="12" :sm="getGridSize(10, mode)" :md="getGridSize(8, mode)" :lg="getGridSize(6, mode)">
                <b-form-group>
                  <h4 class="page-sub-header">Client secret</h4>
                  <div class="floating-input-field">
                    <b-form-input
                      id="client-secret"
                      :class="{
                        'is-invalid': !securityMethodForm.clientSecret && formSubmitted,
                      }"
                      type="text"
                      placeholder=" "
                      v-model="securityMethodForm.clientSecret"
                      required
                    ></b-form-input>
                    <label for="client-secret">Client secret</label>
                    <b-form-invalid-feedback class="d-block" v-if="!securityMethodForm.clientSecret && formSubmitted"
                      >Client secret required.</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="12" :sm="getGridSize(10, mode)" :md="getGridSize(8, mode)" :lg="getGridSize(6, mode)">
                <b-form-group>
                  <h4 class="page-sub-header">Scope</h4>
                  <div class="floating-input-field">
                    <b-form-input
                      id="scope"
                      :class="{
                        'is-invalid': !securityMethodForm.scope && formSubmitted,
                      }"
                      type="text"
                      placeholder=" "
                      v-model="securityMethodForm.scope"
                      required
                    ></b-form-input>
                    <label for="scope">Scope</label>
                    <b-form-invalid-feedback class="d-block" v-if="!securityMethodForm.scope && formSubmitted">Scope required.</b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="12" :sm="getGridSize(10, mode)" :md="getGridSize(8, mode)" :lg="getGridSize(6, mode)">
                <b-form-group>
                  <h4 class="page-sub-header">Grant type</h4>
                  <div class="floating-input-field">
                    <b-form-input id="grant-type" type="text" placeholder=" " readonly v-model="securityMethodForm.grantType" required></b-form-input>
                    <label for="grant-type">Grant type</label>
                  </div>
                </b-form-group>
              </b-col>
            </b-form-row>
          </template>
          <b-form-row class="my-4" v-if="this.mode === 'page'">
            <b-col>
              <b-button type="submit" id="GS-CTZB-SETS-SBM" v-activeBlur variant="primary" :disabled="addEditSecurityMethodLoading"
                >Save <b-spinner v-if="addEditSecurityMethodLoading" label="Spinning" small class="ml-2"></b-spinner
              ></b-button>
              <b-button type="button" id="GS-CTZB-SETS-CNL" v-activeBlur @click="onReset()" class="ml-3" variant="outline-secondary">Cancel</b-button>
            </b-col>
          </b-form-row>
        </b-form>
      </template>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import { DISPLAY_MESSAGES } from '@/utilities/constants'
import { HTTPCLIENT_TYPE_ENUMS, HTTPCLIENT_TYPES } from '@/utilities/settings-constants'
import { useAxios } from '@/composables/useAxios'
import { ServiceUrls } from '@/utilities/serviceUrls'
import { tenantSpecificURL } from '@/utilities/utilities'
import { useValidateFields } from '@/composables/useValidateFields'
import { useGridSize } from '@/composables/useGridSize'
import LoaderIcon from '@/assets/svg/loader.svg'

export default {
  name: 'AddEditSecurityMethod',
  setup() {
    const { getGridSize } = useGridSize()
    const { validateUrl, scrollToErrorMessage } = useValidateFields()
    const {
      isLoading: addEditSecurityMethodLoading,
      response: addEditSecurityMethodResponse,
      error: addEditSecurityMethodError,
      callApi: addEditSecuriytMethodApi,
    } = useAxios()
    const {
      isLoading: getSecurityMethodLoading,
      response: getSecurityMethodResponse,
      error: getSecurityMethodError,
      callApi: getSecurityMethodApi,
    } = useAxios()
    const { response: getDefaultSecurityMethodIdRes, callApi: getDefaultSecurityMethodIdAPI } = useAxios()
    const { response: securityMethodsResponse, callApi: securiytMethodsApi } = useAxios()
    return {
      getGridSize,
      validateUrl,
      scrollToErrorMessage,
      addEditSecurityMethodLoading,
      addEditSecurityMethodResponse,
      addEditSecurityMethodError,
      addEditSecuriytMethodApi,
      getSecurityMethodLoading,
      getSecurityMethodResponse,
      getSecurityMethodError,
      getSecurityMethodApi,
      getDefaultSecurityMethodIdRes,
      getDefaultSecurityMethodIdAPI,
      securityMethodsResponse,
      securiytMethodsApi,
    }
  },
  props: {
    mode: {
      default: 'page',
      type: String,
    },
  },
  components: {
    LoaderIcon,
  },
  data() {
    return {
      isEditPage: false,
      cloneSecurityMethodForm: null,
      securityMethodForm: {
        id: 0,
        httpClientType: null,
        methodName: null,
        apiKeyName: null,
        apiKeyValue: null,
        authenticationUrl: null,
        clientId: null,
        clientSecret: null,
        scope: null,
        grantType: 'client_credentials',
        isDefault: false,
      },
      formSubmitted: false,
      httpClientTypeEnum: HTTPCLIENT_TYPE_ENUMS,
      httpClientTypeOptions: HTTPCLIENT_TYPES,
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
    }
  },
  computed: {
    restrictAddSecurityMethodToMultipleCity() {
      if (this.mode === 'sidebar' && this.$route.params.tenantID) {
        return false
      }
      return this.selectedTenant.length > 1 || this.selectedTenant.some((tenant) => tenant.tenantID === 0)
    },
    showLoader() {
      return this.isEditPage ? this.getSecurityMethodLoading : false
    },
    isDefaultMethodExist() {
      if (this.isEditPage) {
        return this.getDefaultSecurityMethodIdRes ? this.getDefaultSecurityMethodIdRes !== this.securityMethodForm.id : false
      } else {
        return !!this.getDefaultSecurityMethodIdRes
      }
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
    }),
  },
  created() {
    if (this.$route.name === 'Settings-edit-security-method') {
      this.isEditPage = true
      this.getSecurityMethod()
    }
    this.getDefaultSecurityMethodId()
  },
  mounted() {
    this.cloneSecurityMethodForm = cloneDeep(this.securityMethodForm)
  },
  methods: {
    showToaster(message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type,
      })
    },
    async getDefaultSecurityMethodId() {
      if (!this.restrictAddSecurityMethodToMultipleCity) {
        const url = this.$route.params.tenantID
          ? `${ServiceUrls.getDefaultSecurityMethodId}/${this.$route.params.tenantID}`
          : tenantSpecificURL(ServiceUrls.getDefaultSecurityMethodId)
        await this.getDefaultSecurityMethodIdAPI({ method: 'get', url })
      }
    },
    async getSecurityMethod() {
      if (this.isEditPage && !this.restrictAddSecurityMethodToMultipleCity) {
        await this.getSecurityMethodApi({ method: 'get', url: `${ServiceUrls.getSecurityMethod}/${this.$route.params.id}` })
        if (this.getSecurityMethodResponse) {
          this.securityMethodForm = this.getSecurityMethodResponse
          this.cloneSecurityMethodForm = cloneDeep(this.securityMethodForm)
        }
        if (this.getSecurityMethodError) {
          this.showToaster(false, 'SECURITY_METHOD_DETAILS_ERROR', 'danger')
        }
      }
    },
    onChangeHttpClientType() {
      if (this.securityMethodForm.httpClientType === this.httpClientTypeEnum.OAUTH2) {
        this.securityMethodForm.grantType = 'client_credentials'
      }
    },
    onReset() {
      this.securityMethodForm = cloneDeep(this.cloneSecurityMethodForm)
      this.formSubmitted = false
    },
    formatForm() {
      if (this.securityMethodForm.httpClientType === this.httpClientTypeEnum.SIMPLE_AUTH) {
        this.securityMethodForm.authenticationUrl = null
        this.securityMethodForm.clientId = null
        this.securityMethodForm.clientSecret = null
        this.securityMethodForm.scope = null
        this.securityMethodForm.grantType = null
      } else {
        this.securityMethodForm.apiKeyName = null
        this.securityMethodForm.apiKeyValue = null
      }
    },
    validateSecurityMethodForm() {
      const securityMethodName = !!this.securityMethodForm.methodName
      const httpClientType = !!this.securityMethodForm.httpClientType
      const apiKeyName = this.securityMethodForm.httpClientType === this.httpClientTypeEnum.SIMPLE_AUTH ? !!this.securityMethodForm.apiKeyName : true
      const apiKeyValue = this.securityMethodForm.httpClientType === this.httpClientTypeEnum.SIMPLE_AUTH ? !!this.securityMethodForm.apiKeyValue : true
      const authenticationUrl =
        this.securityMethodForm.httpClientType === this.httpClientTypeEnum.OAUTH2 ? this.validateUrl(this.securityMethodForm.authenticationUrl) : true
      const clientId = this.securityMethodForm.httpClientType === this.httpClientTypeEnum.OAUTH2 ? !!this.securityMethodForm.clientId : true
      const clientSecret = this.securityMethodForm.httpClientType === this.httpClientTypeEnum.OAUTH2 ? !!this.securityMethodForm.clientSecret : true
      const scope = this.securityMethodForm.httpClientType === this.httpClientTypeEnum.OAUTH2 ? !!this.securityMethodForm.scope : true

      return securityMethodName && httpClientType && apiKeyName && apiKeyValue && authenticationUrl && clientId && clientSecret && scope
    },
    async onSubmit() {
      this.formSubmitted = true
      this.scrollToErrorMessage()
      if (this.validateSecurityMethodForm()) {
        this.formatForm()
        const url = this.isEditPage ? ServiceUrls.updateSecurityMethod : ServiceUrls.addSecurityMethod
        if (this.mode === 'sidebar' && this.$route.params.tenantID) {
          this.securityMethodForm.tenantId = this.$route.params.tenantID
        } else {
          this.securityMethodForm.tenantId = this.selectedTenant[0].tenantID
        }
        await this.addEditSecuriytMethodApi({ method: 'post', url: url, data: this.securityMethodForm })
        if (this.addEditSecurityMethodResponse) {
          const toastMessagekey = this.isEditPage ? 'UPDATE_SECURITY_METHOD' : 'ADD_SECURITY_METHOD'
          this.showToaster(false, toastMessagekey, 'success')
          if (this.mode === 'sidebar') {
            this.$emit('close')
            const url = this.$route.params.tenantID
              ? `${ServiceUrls.getSecurityMethods}/${this.$route.params.tenantID}`
              : tenantSpecificURL(ServiceUrls.getSecurityMethods)
            await this.securiytMethodsApi({ method: 'get', url })
            if (this.securityMethodsResponse) {
              this.$store.commit('settings/setSecurityMethods', this.securityMethodsResponse)
            }
          } else {
            this.$router.push('/settings/settings-categories/security')
          }
        }
        if (this.addEditSecurityMethodError) {
          if (this.addEditSecurityMethodError?.errorMessage) {
            this.showToaster(this.addEditSecurityMethodError?.errorMessage, false, 'danger')
          } else {
            this.showToaster(false, 'FAILED', 'danger')
          }
        }
      }
    },
  },
  watch: {
    selectedTenant() {
      this.getDefaultSecurityMethodId()
      this.getSecurityMethod()
    },
  },
}
</script>
